
import { AnyObject } from '@casl/ability/dist/types/types';
import { Expose, plainToClass, Transform } from 'class-transformer';
import attachment from '@/components/attachment/attachment.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, PropSync } from 'vue-property-decorator';
import dayjs from 'dayjs';
import isEmpty from '@/app/utils/object.utils';

class GuestInfo {
  // @Expose({name: ''})
  //   'Full Name'!: string;
  @Expose({ name: 'nationality' })
  Nationality!: string;
  @Transform(({ value }) => dayjs(value).format('DD-MM-YYYY'))
  @Expose({ name: 'dateOfBirth' })
  Birthday!: string;
  @Expose({ name: 'email' })
  Email!: string;
  @Transform(({ value }) => value.numberFormatted)
  @Expose({ name: 'phone' })
  'Contact Number'!: string;
  @Transform(({ value }) => {
    if (value.city) return Object.values(value).join(', ');
  })
  @Expose({ name: 'address' })
  'Residential Address'!: Record<string, unknown>;
  // @Transform(({ value }) => {
  //   return value === true ? 'Yes' : 'No';
  // })
  @Expose({ name: 'resident' })
  'Resident'!: string;
  @Expose({ name: 'passportId' })
  'Passport/ID Number'!: string;
}

class minorInfo {
  @Expose({ name: 'requirements' })
  'Special Requirements'!: string;
  @Transform(({ value }) => dayjs(value).format('DD-MM-YYYY'))
  @Expose({ name: 'dateOfBirth' })
  Birthday!: string;
}

@Component({
  components: {
    attachment,
  },
})
export default class GuestProfile extends Vue {
  @Prop() data: any;

  @Prop() minor: any;

  get guestInfo() {
    const data = this.data.guest;
    if (this.minor === true) return plainToClass(minorInfo, { ...data, ...data.profile }, { excludeExtraneousValues: true });
    return plainToClass(GuestInfo, { ...data, ...data.profile, ...data.phone }, { excludeExtraneousValues: true });
  }

  showDocument() {
    return '';
  }

  getListKey(key: any) {
    if (key === 'Resident') {
      // return key + ' of ' + this.data.booking.accommodation.address.formatted_address.split(',').slice(-1)[0];
      return 'Resident of';
    }

    return key;
  }

  valueCheck(value: any) {
    return value && value !== null && value !== 'Invalid Date' && value !== 'true' && value !== 'false';
  }
}
