
import { Expose, plainToClass, Transform, Type } from 'class-transformer';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, PropSync } from 'vue-property-decorator';
import healthCheck from '../../../guests/schema/health-check';
import userModule from '@/store/modules/user/user.module';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import dayjs from 'dayjs';
import TermsModal from '@/components/termsModal2/TermsModal2.vue';
import { guestModule } from '../../../../store/modules/guest.module';

class Health {
  // @Expose({name: ''})
  //   'Full Name'!: string;
  @Expose({ name: 'age' })
  Age!: string;
  @Expose({ name: 'fitnessLevel' })
  Fitness!: string;
  @Expose({ name: 'physicalImpairments' })
  'Physical Impairments'!: string;
  @Expose({ name: 'travelRecently' })
  'Recent travel'!: string;
  @Expose({ name: 'contactRecently' })
  'CovID contact'!: string;
  @Expose({ name: 'recentTest' })
  'Awaiting CoVID test result'!: string;
}

class IndemnityGuestInfo {
  // @Expose({name: ''})
  //   'Full Name'!: string;
  @Expose({ name: 'email' })
  'Email Address:'!: string;
  @Transform(({ value }) => value.numberFormatted)
  @Expose({ name: 'phone' })
  'Contact Number'!: string;
  @Transform(({ value }) => Object.values(value).join(', '))
  @Expose({ name: 'address' })
  'Residential Address'!: Record<string, unknown>;
  // @Transform(({ value }) => {
  //   return value === true ? 'Yes' : 'No';
  // })
  @Expose({ name: 'resident' })
  'Resident'!: string;
  @Expose({ name: 'nationality' })
  'Nationality:'!: string;
  @Expose({ name: 'passportId' })
  'Passport/ID Number'!: string;
  // @Expose({ name: 'lastName' })
  // 'Last Name(As Per Passport)'!: string;
  @Expose({ name: 'checkInDate' })
  @Type(() => Date)
  @Transform(({ value }) => dayjs(value).format('DD-MM-YYYY'))
  'Arrival Date'!: string;
  @Type(() => Date)
  @Transform(({ value }) => dayjs(value).format('DD-MM-YYYY'))
  @Expose({ name: 'checkOutDate' })
  'Departure Date'!: string;
}

@Component({
  components: {
    TermsModal,
  },
})
export default class HealthScreen extends Vue {
  @Prop() data: any;

  popupActiveSync = false;

  termsModalFlag = false;

  newTemperature = {
    addedAt: '',
    temperature: '',
  };

  get guestInfo() {
    if (this.data.minor === true) return this.data.parent;
    return this.data.guest;
  }
  get adultInfo() {
    return this.guestInfo.guestAnswers;
  }

  get flippedHealthQuestions() {
    return this.data.guest.guestAnswers.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));
  }

  dateFormat(date: string) {
    const newDate = new Date(date);
    let month = '' + (newDate.getMonth() + 1);
    let day = '' + newDate.getDate();
    const year = newDate.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('-');
  }

  validateTemp() {
    if (this.newTemperature.temperature && this.newTemperature) {
      this.addTemp();
    }
  }

  addTemp() {
    if (!this.data.guest.temperatureCheck) {
      this.data.guest.temperatureCheck = [];
    }
    this.data.guest.temperatureCheck.push(this.newTemperature);
    this.popupActiveSync = false;
    this.newTemperature = {
      addedAt: '',
      temperature: '',
    };
    guestModule.setEditingFlag(true);
  }

  get temperatures() {
    return this.data.guest.temperatureCheck;
  }

  getAnswer(answer: any[]) {
    if (answer.length > 1) {
      return answer.map((v) => v['value']).join(', ');
    }
    return answer[0].value;
  }

  get IndemnityGuestInfo() {
    const guest = this.guestInfo;
    return plainToClass(IndemnityGuestInfo, { ...guest, ...guest.profile, ...this.data.booking }, { excludeExtraneousValues: true });
  }

  get accomodationInfo() {
    const booking = this.data.booking;
    const owner = userModule.user;
    const organisation = tenantModule.tenant;

    return {
      'Business Name / Trading Name:': booking.accommodation.name,
      'Contact Full name:': booking.accommodation.contactPerson,
      Address: booking.accommodation.address.formatted_address,
      // 'Physical Address of event or activity:': booking.accommodation.address.formatted_address,
      'Contact Number': booking.accommodation.phone.numberFormatted,
      'Email:': booking.accommodation.email,
    };
  }

  viewQuestions() {
    this.termsModalFlag = true;
  }

  getListKey(key: any) {
    if (key === 'Resident') {
      return key + ' of ' + this.data.booking.accommodation.address.formatted_address.split(',').slice(-1)[0];
    }
    return key;
  }
}
