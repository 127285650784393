
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { FilterIcon } from 'vue-feather-icons';
import BookingTable from '@/modules/booking/components/BookingTable.vue';
// import FIELDS from '@/modules/booking/schema/bookingform';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import service from '@/services/app-http-client';
import { guestModule } from '@/store/modules/guest.module';
import { Loading } from '@/modules/auth/store/auth.service';
import TableRowSkeleton from '@/components/skeletons/TableRowSkeletons.vue';
import TermsModal from '@/components/termsmodal/TermsModal.vue';
import BookingAddModal from '@/modules/booking/components/BookingAddModal.vue';
import { Watch } from 'vue-property-decorator';
import { appModule } from '@/store/modules/app.module';
import { mapGetters, mapState } from 'vuex';
import isEmpty from '../../../app/utils/object.utils';
import VueTour2 from '@/components/v-tour/VueTour2.vue';
import { AccommodationDto } from '../../../app/entities/tenant/accommodation.dto';
import { Socket } from 'vue-socket.io-extended';
import Guest from '../../../app/entities/tenant/guest.dto';
@Component({
  components: {
    BookingTable,
    FilterIcon,
    TableRowSkeleton,
    TermsModal,
    BookingAddModal,
    VueTour2,
  },
  computed: {
    ...mapState('app', ['tourActive']),
    ...mapGetters('tenant', ['accommodationList']),
  },
})
export default class Bookings extends Vue {
  private readonly accommodationList!: AccommodationDto[];

  modal = false;

  btnClass = '';

  addBooking = false;

  accommodation = '';

  loading = false;

  guestDetailsFlag = false;

  popupActiveSync = false;

  editBooking = null;

  filter = {
    accommodation: '',
    checkInDate: null,
    checkoutDate: null,
    status: [],
  };

  bookings = {};

  isActive = true;

  @Watch('editBooking')
  handleBooking(booking: any) {
    if (booking) {
      this.popupActiveSync = true;
    }
  }
  @Watch('popupActiveSync')
  handle() {
    if (this.popupActiveSync == false) {
      this.filter = {
        accommodation: '',
        checkInDate: null,
        checkoutDate: null,
        status: [],
      };
    }
  }
  async clear() {
    this.editBooking = null;
    //this.refresh();
  }

  async mounted() {
    // const eventSource = new EventSource(`${process.env.VUE_APP_API_URL}/v1/api/user/stream/${userModule.user?.id}`);
    // eventSource.onmessage = ({ data }) => {
    //   console.log('New message', JSON.parse(data));
    // };
    return this.findAllBookings();
  }

  @Loading
  async findAllBookings(): Promise<void> {
    this.loading = true;
    await guestModule.findAllBookings();
    if (this.entityList.length === 0) {
      this.addBooking = true;
    }
    this.loading = false;
  }

  refresh() {
    this.findAllBookings();
    this.filter = {
      accommodation: '',
      checkInDate: null,
      checkoutDate: null,
      status: [],
    };
  }

  get entityList() {
    return Object.values(guestModule.bookings);
  }

  set entityList(_val) {
    // tenantModule.setAccommodation(val);
  }

  get buttonText() {
    if (this.addBooking && this.guestDetailsFlag === true) return 'Cancel';
    return 'Add Booking';
  }

  get tourActive() {
    return isEmpty(appModule.tourActive) && this.$route.path.includes('organisation');
  }

  async saveBooking(event: any) {
    await tenantModule.saveBooking(event);
  }

  @Loading
  async archived() {
    await guestModule.findAllBookings({
      accommodation: 'ARCHIVED',
      //couldnt get my own propeties to work
      /* archived: true, */
    });
  }

  @Loading
  async search() {
    await guestModule.findAllBookings(this.filter);
  }

  async bookingSearch() {
    if (this.filter.status.length || this.filter.accommodation.length) {
      await this.search();
    } else {
      const popupResponse = await this.$swal({
        title: 'No filters',
        text: 'Please select a filter for your search',
        icon: 'error',
        showConfirmButton: true,
      });
    }

    //const resp = await service.get('/booking', { params: this.filter });
    // const resp = await this.$store.dispatch('booking/bookingSearch', this.filter);
    //this.entityList = resp.data;
  }

  async createBooking() {
    // if (this.$can('create', 'booking')) {
    if (tenantModule.tenant.subscription.isActive) {
      if (tenantModule.accommodations?.length > 0) {
        this.editBooking = null;
        this.addBooking = !this.addBooking;
        this.popupActiveSync = true;
      } else {
        const popupResponse = await this.$swal({
          title: 'No Accommodations',
          text: 'You have no active accommodations. Please add an accommodations to your account to start adding bookings.',
          icon: 'error',
          showConfirmButton: true,
          confirmButtonText: 'Close',
        });
        /*         if (popupResponse.isConfirmed) {
          this.$router.push(Routes.ORGANISATION_ACCOMMODATION);
        } */
      }
    } else {
      const popupResponse = await this.$swal({
        title: 'No credits',
        text: 'Your account is inactive please add a subscription to your account to start adding bookings',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonText: 'View Pricing',
      });

      if (popupResponse.isConfirmed) {
        tenantModule.setShowPricing(true);
      }
    }
  }

  modalFlag() {
    this.modal = !this.modal;
    this.btnClass = this.modal ? 'opacity-0' : '';
  }

  filterMargin() {
    return this.modal ? 'mb-32' : 'mb-8';
  }

  // @Socket('booking')
  // onBookingUpdate({ confirmation }: any) {
  //   console.log(confirmation);
  //   guestModule.findAllBookings().then(() => {
  //     if (confirmation) {
  //       this.$swal('Saved!', 'Your booking has been saved.', 'success');
  //     }
  //   });
  // }
}
