
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, PropSync } from 'vue-property-decorator';
import attachment from '@/components/attachment/attachment.vue';
import VsDivider from '../../../../components/vx-divider/vsDivider.vue';
import TermsModal from '@/components/termsModal2/TermsModal2.vue';
import { Expose, plainToClass, Transform, Type } from 'class-transformer';
import userModule from '@/store/modules/user/user.module';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import { transform } from 'lodash';
import dayjs from 'dayjs';

@Component({
  components: {
    attachment,
    VsDivider,
    TermsModal,
  },
})
export default class AdditionalInfo extends Vue {
  @Prop() data: any;

  get guestInfo() {

    const data = this.data.booking;
    const guest = this.data.guest;
    return {
      'Bed configuration': guest.guestAdditionalInformation?.bedConfig,
      'Requires infant cots': guest.guestAdditionalInformation?.infantCots,
      'Sharing a room with': guest.guestAdditionalInformation?.roomSharing,
      'Bush dinner': guest.guestAdditionalInformation?.bushDinner,
      'Bush walk': guest.guestAdditionalInformation?.bushWalk,
      'Special ocassion': guest.guestAdditionalInformation?.specialOccasion,
      'Specific interests': guest.guestAdditionalInformation?.specificInterests,
      'Carrying a firearm': guest.guestAdditionalInformation?.firearm,
    };
  }
 
}
