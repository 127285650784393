const addStyles = (win: any, styles: any) => {
  styles.forEach((style: any) => {
    const link = win.document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.setAttribute('href', style);
    win.document.getElementsByTagName('head')[0].appendChild(link);
  });
};

//PRINT FUNCTION - VueHtmlToPaper package function that I copied into this component to input the tailwind list classes
export const customPrint = (el: any, localOptions: any, cb = () => true) => {
  const options = {};
  const defaultName = '_blank';
  const defaultSpecs = ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'];
  // const defaultReplace = true;
  const defaultStyles: any = [];
  let { name = defaultName, specs = defaultSpecs, styles = defaultStyles }: any = options;

  if (localOptions) {
    if (localOptions.name) name = localOptions.name;
    if (localOptions.specs) specs = localOptions.specs;
    // if (localOptions.replace) replace = localOptions.replace;
    if (localOptions.styles) styles = localOptions.styles;
  }

  specs = specs.length ? specs.join(',') : '';

  const element = window.document.getElementById(el);

  if (!element) {
    alert(`Element to print #${el} not found!`);
    return;
  }

  const url = '';
  const win: any = window.open(url, name, specs);

  if (win == null) {
    return;
  }

  win.document.write(`
  <html>
      <head>
      <title>${window.document.title}</title>
      </head>
      <body>
      ${element.innerHTML}
      </body>
  </html>
  `);

  //Adding SVG Page styling
  const qrSvg = win.document.getElementsByTagName('svg');
  for (let i = 0; i < qrSvg.length || 0; i++) {
    qrSvg[i].setAttribute('class', 'm-auto');
  }

  //Adding list styling
  const listParents = win.document.getElementsByTagName('ol');

  for (let i = 0; i < listParents.length || 0; i++) {
    listParents[i].className += 'list-decimal mb-4 ml-4';
  }

  addStyles(win, styles);

  setTimeout(() => {
    win.document.close();
    win.focus();
    win.print();
    win.close();
    cb();
  }, 1000);

  return true;
};
