
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, PropSync } from 'vue-property-decorator';
import attachment from '@/components/attachment/attachment.vue';
import VsDivider from '../../../../components/vx-divider/vsDivider.vue';
import TermsModal from '@/components/termsModal2/TermsModal2.vue';
import { Expose, plainToClass, Transform, Type } from 'class-transformer';
import userModule from '@/store/modules/user/user.module';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import { transform } from 'lodash';
import dayjs from 'dayjs';

@Component({
  components: {
    attachment,
    VsDivider,
    TermsModal,
  },
})
export default class GuestMedical extends Vue {
  @Prop() data: any;

  showProof = false;

  get guestInfo() {
    const guest = this.data.guest;

    return {
      'Travel insurance': guest.profile.travelInsurance,
      'Medical aid provider': guest.profile.medicalAidProvider,
      'Medical aid policy number': guest.profile.medicalAidNumber,
      'Medical aid emergency number': guest.profile.medicalAidEmergency.numberFormatted,
      'Next of kin contact number': guest.profile.emergencyContact.numberFormatted,
      'Dietry requirements': guest.dietaryRequirements,
      Allergies: guest.profile.allergies,
      'Medical conditions': guest.profile.medicalConditions,
      'Special accessibility requirements': guest.profile.specialRequirements,
      'Vaccinated against COVID-19': guest.profile.vaccinated,
      'COVID-19 vaccines up to date': guest.profile.vaccineUpToDate,
    };
  }
}
