function guestBookingInfo({ booking, guest }: any) {
  let minors = [];
  if (guest.minor) {
    minors = guest.minor
      .map((minor: any) => {
        return minor.firstName;
      })
      .join(', ');
  }

  return {
    checkInSchema: [
      {
        component: 'dl',
        children: [
          {
            component: 'dt',
            children: 'Check-In:',
          },
          {
            component: 'dd',
            children: booking.checkInDate,
          },
          {
            component: 'dt',
            children: 'Check-Out:',
          },
          {
            component: 'dd',
            children: booking.checkOutDate,
          },
          {
            component: 'dt',
            children: 'Minors:',
          },
          {
            component: 'dd',
            children: minors || 'No Minors',
          },
          {
            component: 'dt',
            children: 'Dietary Requirements',
          },
          {
            component: 'dd',
            children: guest.dietaryRequirements || 'None',
          },
          {
            component: 'dt',
            children: 'Luggage:',
          },
          {
            component: 'dd',
            children: guest.luggageHandling || 'Do not need assistance with luggage.',
          },
        ],
      },
    ],

    healthCheckSchema: guest.healthCheck
      ? [
          {
            component: 'dl',
            children: [
              {
                component: 'dt',
                children: 'Age:',
              },
              {
                component: 'dd',
                children: guest.healthCheck.age || 'not filled in',
              },
              {
                component: 'dt',
                children: 'Fitness:',
              },
              {
                component: 'dd',
                children: guest.healthCheck.fitnessLevel,
              },
              {
                component: 'dt',
                children: 'Physical impairments:',
              },
              {
                component: 'dd',
                children: guest.healthCheck.physicalImpairments,
              },
              {
                component: 'dt',
                children: 'Smoking:',
              },
              {
                component: 'dd',
                children: guest.healthCheck.smoking || 'No',
              },
              {
                component: 'dt',
                children: 'Recent travel:',
              },
              {
                component: 'dd',
                children: guest.healthCheck.travelRecently,
              },
              {
                component: 'dt',
                children: 'CoVID Contact:',
              },
              {
                component: 'dd',
                children: guest.healthCheck.contactRecently || 'No',
              },
              {
                component: 'dt',
                children: 'Awaiting CoVID test results:',
              },
              {
                component: 'dd',
                children: guest.healthCheck.recentTest,
              },
              {
                component: 'dt',
                children: 'Health Insurance:',
              },
              {
                component: 'dd',
                children: guest.healthCheck.healthInsurance || 'No',
              },
              {
                component: 'dt',
                children: 'Accept T&Cs:',
              },
              {
                component: 'dd',
                children: guest.healthCheck.termsAccepted || 'No',
              },
            ],
          },
        ]
      : null,

    guestProfileSchema: [
      {
        component: 'dl',
        children: [
          {
            component: 'dt',
            class: 'font-medium text-lg uppercase',
            children: `${guest.firstName} ${guest.lastName}`,
          },
          {
            component: 'dd',
            class: 'font-medium text-lg',
            children: guest?.profile?.nationality,
          },
          {
            component: 'dt',
            children: 'Birthday:',
          },
          {
            component: 'dd',
            children: guest?.profile?.dateOfBirth || 'nope',
          },
          {
            component: 'dt',
            children: 'Email:',
          },
          {
            component: 'dd',
            children: guest.email,
          },
          {
            component: 'dt',
            children: 'Contact:',
          },
          {
            component: 'dd',
            children: guest?.user?.mobilePhone,
          },
          {
            component: 'dt',
            children: 'Passport/ID:',
          },
          {
            component: 'dd',
            children: guest?.profile?.passportId,
          },
          {
            component: 'dt',
            children: 'Insurance Provider:',
          },
          {
            component: 'dd',
            children: guest?.profile?.insuranceProvider,
          },
          {
            component: 'dt',
            children: 'Insurance number:',
          },
          {
            component: 'dd',
            children: guest?.profile?.insuranceNumber,
          },
          {
            component: 'dt',
            children: 'Emergency Person:',
          },
          {
            component: 'dd',
            children: guest?.profile?.emergencyContact,
          },
          {
            component: 'dt',
            children: 'Emergency Contact:',
          },
          {
            component: 'dd',
            children: guest?.profile?.medicalAidEmergency,
          },
          {
            component: 'dt',
            children: 'CoVID Status:',
          },
          {
            component: 'dd',
            children: [
              {
                component: 'div',
                class: 'inline-block bg-teal-200 text-green-600 covid-status',
                children: 'Approved',
              },
            ],
          },
        ],
      },
    ],
  };
}

export default guestBookingInfo;
