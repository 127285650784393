<route>
{
  "meta": {
    "requiresAuth": true,
 "breadcrumb": [
          { "title": "Home", "url": "/organisation/dashboard" },
          { "title": "Booking", "active": true }
      ],
    "title": "Booking",
    "resource": "booking",
    "action": "read"
  }
}
</route>
<template>
  <div class="organisation">
    <Booking />
  </div>
</template>

<script>
// @ is an alias to /src
import Booking from '@/modules/booking/pages/Booking.vue';

export default {
  name: 'booking',
  components: {
    Booking,
  },
  layout: 'AppMain',
};
</script>
