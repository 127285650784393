
import Vue from 'vue';
import Component from 'vue-class-component';
import userModule from '@/store/modules/user/user.module';

@Component({})
export default class BookingGuestProfileCard extends Vue {
  get user() {
    return userModule.user;
  }

  get userNumber() {
    return userModule.user?.tenant?.phone || null;
  }
}
