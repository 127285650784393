
/* eslint-disable no-param-reassign */
import { Component, Emit, PropSync, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import SchemaMixin from '@/shared/mixins/schema.mixins';
import { IBookingDto } from '@/app/entities';
import dayjs from '@/app/utils/date';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import { guestModule } from '@/store/modules/guest.module';
import userModule from '@/store/modules/user/user.module';
import Accordion from '@/components/accordion/Accordion.vue';
import { BookingStatus } from '@/app/dto/bookingDto';
import attachment from '@/components/attachment/attachment.vue';
import Guest from '@/app/entities/tenant/guest.dto';
import Indemnity from '@/app/entities/tenant/Indemnity.dto';
import isEmpty from '../../../app/utils/object.utils';
import * as _ from 'lodash';
import { Loading } from '@/modules/auth/store/auth.service';
import ToastificationContent from '@/app/components/toastification/ToastificationContent.vue';
import { POSITION } from 'vue-toastification';

interface minorParentObj {
  name?: string;
  surname?: string;
  parentName?: string;
  parentSurname?: string;
  parent: any;
}

@Component({
  components: {
    Accordion,
    attachment,
  },
})
export default class BookingAddModal extends mixins(SchemaMixin) {
  @PropSync('modalFlag') modalFlagSync: boolean;

  @PropSync('editBooking') editBookingSync: IBookingDto;

  $refs!: any;

  minor = [];

  selected = 0;

  counterGuest = 1;

  initialGuests = 0;

  booking: IBookingDto = {} as IBookingDto;

  guest: Guest[] = [];

  initialMinors = 0;

  indemnity: Indemnity[] = [];
  editSelected = false;

  minors = [] as minorParentObj[];

  get guests() {
    return this.guest;
  }

  set guests(v: any) {
    this.guest = v;
  }

  get registeredMinorTotal() {
    if (this.minors) return this.minors.length;
    return 0;
  }

  get bookingEdit() {
    return this.editBookingSync !== null;
  }

  get guestEdit() {
    const isTheSame = _.isEqual(this.booking.guest, this.guest);
    return this.editBookingSync !== null && !isTheSame;
  }

  async created() {
    if (this.editBookingSync) {
      this.editSelected = true;
      this.editBookingSync.dates = {
        checkInDate: this.editBookingSync.checkInDate,
        checkOutDate: this.editBookingSync.checkOutDate,
      };
      this.booking = _.cloneDeep(this.editBookingSync);
      if (this.booking.guest) {
        this.booking.guest.sort((x, y) => {
          return x.isPrimary == true ? -1 : y.isPrimary == true ? 1 : 0;
        });
      }
      this.guest = _.cloneDeep(this.booking.guest) as Guest[];
      this.counterGuest = this.guest.length;
      this.initialGuests = this.guest.length;
      this.editBookingSync.guest.forEach((guest) => {
        this.initialMinors += guest.minor.length;
      });

      // this.initialMinors = this.booking.numberOfMinors;
    } else {
      this.booking = {
        numberOfMinors: 0,
        numberOfGuests: 0,
        dates: {
          checkInDate: '',
          checkOutDate: '',
        },
      } as IBookingDto;
      const guest = new Guest();
      guest.status = BookingStatus.PENDING;
      guest.isPrimary = true;
      this.guest.push(guest);
    }
    if (Object.keys(tenantModule?.accommodations).length === 0) {
      await tenantModule.getAccommodations();
    }

    this.minors = this.booking.guest?.flatMap((x) => {
      return x.minor.map((y) => {
        return { parent: x.firstName, parentSurname: x.lastName, name: y.firstName, surname: y.lastName };
      });
    });
  }
  customFormatter(date: any) {
    return dayjs(date, 'DD-MM-YYYY');
  }

  get selectedAccommodation() {
    if (this.editBookingSync && this.editSelected) {
      this.indemnity = [];
      this.editSelected = false;
      this.selected = this.accommodationList.findIndex((accom) => accom == this.booking.accommodation?.name);
      const key = parseInt(this.selected as any, 10);
      this.$nextTick(() => {
        this.booking?.indemnity?.forEach((indemnity) => {
          tenantModule.accommodations[key].indemnity.forEach((accIndem) => {
            if (indemnity.name == accIndem.name) {
              this.selectIndemnity(accIndem);
            }
          });
        });
      });

      return tenantModule.accommodations[key].indemnity;
    }
    const key = parseInt(this.selected as any, 10);
    this.indemnity = [];
    return tenantModule.accommodations[key].indemnity;
  }

  get accommodationList(): (string | undefined)[] {
    return tenantModule.accommodations.map((acc: any) => acc.name);
  }

  get mapBooking() {
    return {
      accommodationId: tenantModule.accommodations[this.selected].id,
      numberOfGuests: this.guest?.length as number,
      checkInDate: this.booking.dates.checkInDate,
      checkOutDate: this.booking.dates.checkOutDate,
      invitations: this.guest.length - this.guest.filter((val) => val.email !== null).length,
      minorInvitations: this.booking.numberOfMinors,
      tenantId: tenantModule.tenant.id,
      indemnity: this.indemnity as any,
    };
  }

  addGuest(): void {
    if (this.booking.guest) {
      const guest = new Guest();
      guest.status = BookingStatus.PENDING;
      this.guest.push(guest);
    }
  }

  removeGuest(): void {
    if (this.booking.guest) {
      this.booking.guest.pop();
    }
  }

  closeModal(): void {
    this.modalFlagSync = false;
    this.clear();
  }

  hasIndemnity(v: any) {
    return this.indemnity.includes(v);
  }

  selectIndemnity(indemnity: any) {
    if (this.hasIndemnity(indemnity)) {
      const index = this.indemnity.indexOf(indemnity);
      this.indemnity.splice(index, 1);
    } else {
      this.indemnity.push(indemnity);
    }
  }

  async updateBooking() {
    this.booking.accommodationId = tenantModule.accommodations[this.selected].id as string;
    this.booking.numberOfGuests = this.guest?.length as number;
    this.booking.checkInDate = this.booking.dates.checkInDate;
    this.booking.checkOutDate = this.booking.dates.checkOutDate;
    this.booking.invitations = this.guest.length - this.guest.filter((val) => val.email !== null).length;
    this.booking.minorInvitations = this.booking.numberOfMinors - this.initialMinors;
    this.booking.tenantId = tenantModule.tenant.id;
    this.booking.indemnity = this.indemnity as any;
    this.booking.credits = this.counterGuest - this.initialGuests;
    this.booking.bookingReference = this.booking.bookingReference?.toUpperCase();
    await guestModule.createBooking(this.booking);
    this.closeModal();
    await guestModule.findAllBookings().then(() => {
      this.$swal('Updated!', 'Your booking has been updated.', 'success');
    });
  }

  checkAllGuests() {
    let checkForEmpties = true;
    const guestFormStatus = this.guests.every((_guest: Guest, index: number) => {
      this.$refs[`ref-${index}`].formSubmitted();
      if (this.$refs[`ref-${index}`].hasErrors || (isEmpty(this.guest[index].email) && isEmpty(this.guest[index].phone?.number)))
        return false;
      return true;
    });
    if (this.guest[0].status !== 'PENDING') checkForEmpties = false;
    if (!guestFormStatus && !checkForEmpties) {
      this.$swal({
        title: 'Some guest details are missing',
        text: 'Please complete the guest details',
        icon: 'error',
        showConfirmButton: true,
      });
      return false;
    }
    return true;
  }

  checkPrimaryGuest() {
    this.$refs['ref-0'].formSubmitted();
    if (isEmpty(this.guest[0].email) && isEmpty(this.guest[0].phone?.number)) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: `Please fill in either phone number or email before inviting guest`,
            variant: 'error',
          },
        },
        {
          position: POSITION.TOP_RIGHT,
        },
      );
      return false;
    }
    if (this.$refs['ref-0'].hasErrors) return false;
    return true;
  }

  async checkBooking() {
    if (this.$refs['ref-main'].hasErrors) {
      this.$swal({
        title: 'Some booking details are missing',
        text: 'Please fill out all the booking details',
        icon: 'error',
        showConfirmButton: true,
      });
      return;
    }
    if (!this.indemnity.length) {
      this.$swal({
        title: 'No Indemnities',
        text: 'Please select an indemnity for the booking',
        icon: 'error',
        showConfirmButton: true,
      });
      return;
    }

    if (this.bookingEdit && !this.guestEdit) {
      return this.updateBooking();
    }

    if (this.guestEdit) {
      if (!this.checkAllGuests()) return;
    } else {
      if (!this.checkPrimaryGuest()) return;
    }

    if (this.bookingEdit) {
      return this.saveBooking();
    }
    let isRefMatch = false;
    Object.values(guestModule.bookings).forEach((obj) => {
      if (obj.bookingReference === this.booking.bookingReference?.toUpperCase()) {
        return (isRefMatch = true);
      }
    });
    if (isRefMatch) {
      this.$swal({
        title: 'Duplicate Booking Reference',
        text: 'A booking with that reference already exists',
        icon: 'error',
        showConfirmButton: true,
      });
      return;
    }
    this.saveBooking();
  }

  @Loading
  async saveBooking(): Promise<void> {
    this.booking.accommodationId = tenantModule.accommodations[this.selected].id as string;
    this.booking.numberOfGuests = this.guest?.length as number;
    this.booking.checkInDate = this.booking.dates.checkInDate;
    this.booking.checkOutDate = this.booking.dates.checkOutDate;
    this.booking.invitations = this.guest.length - this.guest.filter((val) => val.email !== null).length;
    this.booking.minorInvitations = this.booking.numberOfMinors - this.initialMinors;
    this.booking.tenantId = tenantModule.tenant.id;
    this.booking.indemnity = this.indemnity as any;
    this.booking.credits = this.counterGuest - this.initialGuests;
    this.booking.bookingReference = this.booking.bookingReference?.toUpperCase();

    const booking = await guestModule.createBooking(this.booking);

    if (booking) {
      this.$gtm.trackEvent({
        event: 'Booking', // Event type [default = 'interaction'] (Optional)
        // category: 'testing', // Dunno
        action: 'created',
        type: 'created', // Event Action
        accommodation: tenantModule.accommodations[this.selected].id, // Accommodation ID
        accom_name: tenantModule.accommodations[this.selected].name, // Accommodation name
        // org: guestModule.organisationId,
        noninteraction: true, // Optional
      });
    }
    const guests = this.guest
      .filter((guest) => guest.status === 'PENDING')
      .map<Guest>((guest, index) => {
        guest.booking = booking;
        guest.mobileNumber = guest.mobileNumber?.replace(/^0+/, '');
        if (this.editBookingSync === null) {
          guest.isPrimary = index === 0 ? true : false;
        }
        return guest;
      });

    await guestModule.createGuest({ guests, booking });

    // eslint-disable-next-line vue/custom-event-name-casing
    if (booking && userModule.user?.id) {
      tenantModule.getTenant();
    }

    guestModule.findAllBookings().then(() => {
      this.$swal('Saved!', 'Your booking has been saved.', 'success');
    });
    this.closeModal();
  }
  destroyed() {
    this.editBookingSync = {} as IBookingDto;
  }
  @Watch('counterGuest')
  counterHandler(event: number) {
    if (event > this.guest.length) {
      const guest = new Guest();
      guest.status = 'PENDING';
      this.guest.push(guest);
    } else if (event < this.guest.length) {
      this.$nextTick(() => {
        this.guest.pop();
      });
    }
    this.$nextTick(() => {
      this.$emitter.emit('updatePanels', event);
    });
  }
  remo() {
    if (this.booking.guest) {
      this.guest.pop();
    }
  }

  get bookingForm() {
    return this.booking.guest;
  }

  @Emit('refresh')
  refresh() {
    return true;
  }
  @Emit('clear')
  clear() {
    return true;
  }

  async deleteBooking() {
    const response = await this.$swal({
      icon: 'warning',
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      position: 'center',
      showConfirmButton: true,
      confirmButtonText: 'Accept',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    });

    if (response.isConfirmed) {
      let status: any[] = [];
      this.editBookingSync.guest.forEach((guest) => {
        status.push(guest.status);
      });
      if (status.includes('CHECKEDIN')) {
        this.$swal(`You can't do that`, `You can't delete a booking with checked in guests.`, 'error');
      } else {
        guestModule.deleteBooking(this.editBookingSync).then(() => {
          this.$swal('Deleted!', 'Your booking has been deleted.', 'success');
          this.editBookingSync = {} as IBookingDto;
          this.refresh();
          this.closeModal();
        });
      }
    }
  }
}
