
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, PropSync, Watch } from 'vue-property-decorator';
import attachment from '@/components/attachment/attachment.vue';
import VsDivider from '../../../../components/vx-divider/vsDivider.vue';
import TermsModal from '@/components/termsModal2/TermsModal2.vue';
import { Expose, plainToClass, Transform, Type } from 'class-transformer';
import userModule from '@/store/modules/user/user.module';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import { transform } from 'lodash';
import dayjs from 'dayjs';

class IndemnityGuestInfo {
  // @Expose({name: ''})
  //   'Full Name'!: string;
  @Expose({ name: 'email' })
  'Email Address:'!: string;
  @Transform(({ value }) => value.numberFormatted)
  @Expose({ name: 'phone' })
  'Contact Number:'!: string;
  @Transform(({ value }) => Object.values(value).join(', '))
  @Expose({ name: 'address' })
  'Residential Address:'!: Record<string, unknown>;
  // @Transform(({ value }) => {
  //   return value === true ? 'Yes' : 'No';
  // })
  @Expose({ name: 'resident' })
  'Resident:'!: string;
  @Expose({ name: 'nationality' })
  'Country / Province:'!: string;
  @Expose({ name: 'passportId' })
  'Passport/ID Number:'!: string;
  // @Expose({ name: 'lastName' })
  // 'Last Name(As Per Passport)'!: string;
  @Expose({ name: 'checkInDate' })
  @Type(() => Date)
  @Transform(({ value }) => dayjs(value).format('DD-MM-YYYY'))
  'Arrival Date:'!: string;
  @Type(() => Date)
  @Transform(({ value }) => dayjs(value).format('DD-MM-YYYY'))
  @Expose({ name: 'checkOutDate' })
  'Departure Date:'!: string;
}

interface indemnity {
  name?: string;
}

@Component({
  components: {
    attachment,
    VsDivider,
    TermsModal,
  },
})
export default class BookingDetails extends Vue {
  @Prop() data: any;

  termsModalFlag = false;

  indemnity: indemnity = {};

  dateFormat(date: string) {
    const newDate = new Date(date);
    let month = '' + (newDate.getMonth() + 1);
    let day = '' + newDate.getDate();
    const year = newDate.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('-');
  }

  get IndemnityGuestInfo() {
    const guest = this.data.guest;
    return plainToClass(IndemnityGuestInfo, { ...guest, ...guest.profile, ...this.data.booking }, { excludeExtraneousValues: true });
  }

  get accomodationInfo() {
    const booking = this.data.booking;
    const owner = userModule.user;
    const organisation = tenantModule.tenant;

    return {
      'Business Name / Trading Name:': booking.accommodation.name,
      'Contact Full name:': booking.accommodation.contactPerson,
      'Address of Indemnified Party:': booking.accommodation.address.formatted_address,
      // 'Physical Address of event or activity:': booking.accommodation.address.formatted_address,
      'Contact Number:': booking.accommodation.phone.numberFormatted,
      'Email:': booking.accommodation.email,
    };
  }

  

  get guestInfo() {
    const data = this.data.booking;

    const guest = this.data.guest;

    return {
      'Check-in': this.dateFormat(data.checkInDate),
      'Check-out': this.dateFormat(data.checkOutDate),
      'Arrival time start': guest.guestArrival?.arrivalTimeStart,
      'Arrival time end': guest.guestArrival?.arrivalTimeEnd,
      'Luggage handling': guest.luggageHandling,
      'Mode of transport': guest.guestArrival?.modeTransport,
      'Vehicle registration': guest.guestArrival?.vehicleRegistration,
      'Flight number': guest.guestArrival?.flightNumber,
      Airline: guest.guestArrival?.airline,
    };
  }

  showIndemnity(indemnity: Record<string, any>) {
    this.indemnity = indemnity;
    this.termsModalFlag = true;
  }

  getListKey(key: any) {
    if (key === 'Resident') {
      return key + ' of ' + this.data.booking.accommodation.address.formatted_address.split(',').slice(-1)[0];
    }
    return key;
  }
}
