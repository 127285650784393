
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import BookingGuestInfo from '@/modules/booking/components/BookingGuestInfo.vue';
import { GuestDto } from '../../../app/dto/guest.dto';
import { IGuestDto } from '../../../app/models/tenant/IBookingDto';
import { IBookingDto } from '../../../app/entities/tenant/IBookingDto';
import StatusMixin from '../../../shared/mixins/status.mixin';
import { tenantModule } from '../../../store/modules/tenant/tenant.module';
import { guestModule } from '../../../store/modules/guest.module';
import tenantService from '@/services/api/tenant.service';
import Guest from '../../../app/entities/tenant/guest.dto';
@Component({
  components: {
    BookingGuestInfo,
  },
})
export default class BookingRow extends mixins(StatusMixin) {
  @Prop() guest!: any;
  @Prop() index!: number;
  @Prop() booking!: any;
  @Prop({ default: false }) minor!: boolean;
  @Prop() parent!: GuestDto;
  @Prop() forcedStatus!: string;

  copiedUrlLink: boolean[] = [];
  parentObject: GuestDto = new GuestDto();

  async doCopy(guest: Guest) {
    const link = await `${process.env.VUE_APP_URL}/guests/booking?id=${guest.token}&org=${tenantModule.tenant.id}`;
    const shortenedLink: any = await tenantService.shortenUrl(link);
    this.$copyText(shortenedLink.shortUrl);
  }

  created() {
    if (this.parent) {
      this.parentObject = Object.assign({}, this.parent, this.forcedStatus ? { status: this.forcedStatus } : {});
    }
  }

  isBirthday() {
    const wholeBirthday = new Date((this.minor && this.guest?.dateOfBirth) || this.guest.profile?.dateOfBirth);
    const birthMonth = wholeBirthday.getMonth();
    const birthDate = wholeBirthday.getDate();

    const wholeCheckIn = new Date(this.booking.checkInDate);
    const checkInMonth = wholeCheckIn.getMonth();
    const checkInDate = wholeCheckIn.getDate();
    const wholeCheckOut = new Date(this.booking.checkOutDate);
    const checkOutMonth = wholeCheckOut.getMonth();
    const checkOutDate = wholeCheckOut.getDate();

    let result = false;

    if (checkInMonth === checkOutMonth && birthMonth === checkInMonth) {
      result = birthDate >= checkInDate && birthDate <= checkOutDate;
    } else {
      result =
        (birthDate >= checkInDate && birthMonth === checkInMonth) ||
        (birthDate <= checkOutDate && birthMonth === checkOutMonth) ||
        (birthMonth > checkInMonth && birthMonth < checkOutMonth);
    }

    return result;
  }
  // healthCheck() {
  //   return this.guest.status === 'CHECKEDIN';
  // }
  get isCopied() {
    return this.copiedUrlLink;
  }
  checkOutGuest(guest: any) {
    this.$swal
      .fire({
        title: 'Check-out guest?',
        text: 'Are you sure you want to check-out this guest?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonColor: 'red',
        cancelButtonText: 'No',
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.guest.status = 'CHECKEDOUT';
          this.$swal('Guest updated', 'The guest has been checked out', 'success');
          const payload = {
            guestInfo: guest,
          };
          guestModule.updateGuest(payload);
        } else {
          return;
        }
      });
  }
  getStatusColor(val: string): string {
    let color;
    switch (val) {
      case 'PENDING':
        color = 'text-red-600';
        break;
      case 'PRECHECKEDIN':
        color = 'text-amber-500';
        break;
      case 'CHECKEDIN':
        color = 'text-lime-500';
        break;
      case 'CHECKEDOUT':
        color = 'text-gray-600';
        break;
      default:
        color = 'text-green-400';
        break;
    }
    return color;
  }
}
