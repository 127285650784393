
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, PropSync } from 'vue-property-decorator';
import GuestProfileCard from '@/modules/guests/components/GuestProfileCard.vue';
import TermsModal from '@/components/termsModal2/TermsModal2.vue';
import BookingGuestProfile from './BookingGuestProfileCard.vue';
import guestBookingInfo from '../schema/bookingGuestform';
import { IBookingDto, IGuestDto } from '../../../app/entities/tenant/IBookingDto';
import GuestProfile from '@/modules/booking/components/guest-info/GuestProfile.vue';
import AdditionalInfo from '@/modules/booking/components/guest-info/AdditionalInfo.vue';
import BookingDetails from '@/modules/booking/components/guest-info/BookingDetails.vue';
import GuestMedical from '@/modules/booking/components/guest-info/GuestMedical.vue';
import HealthScreen from '@/modules/booking/components/guest-info/HealthScreen.vue';
import { guestModule } from '../../../store/modules/guest.module';
import { Loading } from '../../auth/store/auth.service';

interface IBookingData {
  booking: IBookingDto;
  guest: IGuestDto;
  minor?: boolean;
  parent: IGuestDto;
}

@Component({
  components: {
    BookingGuestProfile,
    GuestProfileCard,
    TermsModal,
    GuestProfile,
    BookingDetails,
    HealthScreen,
    AdditionalInfo,
    GuestMedical,
  },
})
export default class BookingGuestInfo extends Vue {
  @PropSync('popupActive') popupActiveSync: any;

  @Prop() bookingData!: IBookingData;

  check = false;

  component = 'GuestProfile';

  checkingIn = false;

  guestUpdateFlag = false;

  bookingObject: any = {};

  pdfDocs = [
    {
      name: 'Jhone_Doe_ID',
      url: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
    },
    {
      name: 'Some other pdf',
      url: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
    },
  ];

  get guestSchema() {
    return guestBookingInfo(this.bookingData);
  }

  get isMinor() {
    return this.bookingData.minor;
  }

  get componentName() {
    return this.component;
  }
  set componentName(val) {
    this.component = val;
  }

  get isCheckingIn() {
    if (this.bookingData.guest.temperatureCheck) {
      if (this.bookingData.guest.temperatureCheck.length === 1 && this.bookingData.guest.status === 'CONFIRMED') return true;
    }
    return false;
  }

  get isHealthCheck() {
    let parentStatus;
    if (!this.bookingData.parent) {
      parentStatus = this.bookingData.guest.status;
    } else {
      parentStatus = this.bookingData.parent.status;
    }
    if (this.bookingData.guest.guestAnswers && this.bookingData.guest.guestAnswers.length > 0)
      if (
        this.bookingData.guest.status === 'CHECKEDIN' ||
        this.bookingData.guest.status === 'CHECKEDOUT' ||
        parentStatus === 'CHECKEDIN' ||
        parentStatus === 'CHECKEDOUT'
      )
        return true;
    return false;
  }

  closeDialog() {
    if (guestModule.editingGuestFlag === true)
      return this.$swal({
        title: 'Unsaved changes',
        text: 'Would you like go back and save your changes before closing?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      })
        .then((result) => {
          if (result.dismiss) {
            guestModule.setEditingFlag(false);
            this.popupActiveSync = false;
          }
          return;
        })
        .then();
    this.popupActiveSync = false;
  }
  @Loading
  async updateGuest() {
    if (this.isCheckingIn) {
      this.checkingIn = true;
    }
    if (this.isMinor) {
      const guest = this.bookingData.guest as any;
      await guestModule.updateMinor(guest);
    } else {
      const guest = {
        guestInfo: {
          id: this.bookingData.guest.id,
          temperatureCheck: this.bookingData.guest.temperatureCheck,
          bookingId: this.bookingData.guest.bookingId,
        } as IGuestDto,
        profile: this.bookingData.guest.profile,
      };
      await guestModule.updateGuest(guest);
    }
    if (this.checkingIn) {
      this.$swal('Guest checked-in', 'The guest has been updated and checked-in', 'success');
    } else {
      this.$swal('Guest updated', 'The guest has been updated', 'success');
    }
    guestModule.setEditingFlag(false);
  }

  get isPendingMinor() {
    if (this.bookingData.minor === true && this.bookingData.guest.firstName === '') {
      return true;
    }
    return false;
  }

  deleteUserTypeCheck(userType: string) {
    this.$swal({
      title: `Deleting a ${userType}`,
      text: `Are you sure you want to delete this ${userType}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        if (userType === 'guest') return this.deleteGuest();
        return this.deleteMinor();
      }
      return;
    });
  }

  @Loading
  async deleteGuest() {
    const deleteGuestIds = { id: this.bookingData.guest.id, bookingId: this.bookingData.booking.id };
    await guestModule.deleteGuest(deleteGuestIds);
    await guestModule.findAllBookings().then(() => {
      this.$swal('Updated!', 'Your booking has been updated.', 'success');
    });
    this.popupActiveSync = false;
  }

  async deleteMinor() {
    this.bookingData.booking.numberOfMinors = this.bookingData.booking.numberOfMinors - 1;
    this.bookingData.booking.minorInvitations = this.bookingData.booking.minorInvitations - 1;
    this.bookingData.booking.credits = 0;
    await guestModule.createBooking(this.bookingData.booking);
    await guestModule.findAllBookings().then(() => {
      this.$swal('Updated!', 'Your booking has been updated.', 'success');
    });
    this.popupActiveSync = false;
  }

  get medical(): { [key: string]: any } {
    const guest = this.bookingData.guest;
    return {
      'Travel insurance': guest.profile?.travelInsurance,
      'Medical aid provider': guest.profile?.medicalAidProvider,
      'Medical aid policy number': guest.profile?.medicalAidNumber,
      'Medical aid emergency number': guest.profile?.medicalAidEmergency.numberFormatted,
      'Next of kin contact number': guest.profile?.emergencyContact.numberFormatted,
      'Dietry requirements': guest.dietaryRequirements,
      Allergies: guest.profile?.allergies,
      'Medical conditions': guest.profile?.medicalConditions,
      'Special accessibility requirements': guest.profile?.specialRequirements,
      'Vaccinated against COVID-19': guest.profile?.vaccinated,
      'COVID-19 vaccines up to date': guest.profile?.vaccineUpToDate,
    };
  }

  get disableMedicalTab() {
    for (let field in this.medical) if (this.medical[field] !== null && this.medical[field] !== '') return true;
    return false;
  }

  get disableExtraTab() {
    for (let field in this.bookingData.guest.guestAdditionalInformation)
      if (this.bookingData.guest.guestAdditionalInformation[field] !== null && field !== 'id') return true;
    return false;
  }
}
