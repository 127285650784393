
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, PropSync } from 'vue-property-decorator';
import { customPrint } from '@/app/utils/print';

@Component
export default class TermsModal2 extends Vue {
  @PropSync('termsModalFlag') check: any;

  @Prop() indemnity!: any;

  @Prop() signature!: any;

  print() {
    customPrint('printText', {
      name: '_blank',
      specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
      styles: [
        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
        'https://unpkg.com/kidlat-css/css/kidlat.css',
        'https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css',
      ],
      timeout: 1000,
      autoClose: true,
      windowTitle: window.document.title,
    });
  }
}
